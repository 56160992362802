import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { LoginService } from '../login.service';
import { ToastService } from '../../../shared/services/toast.service';
import { showSpinner } from '../../../shared/utils/common.utils';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrl: './login-email.component.scss',
})
export class LoginEmailComponent {
  destroyRef = inject(DestroyRef);
  @Input() email?: string;
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private toastService: ToastService,
    private loginService: LoginService,
    private translateService: TranslateService,
  ) {}

  sendLogInEmail() {
    if (this.email) {
      this.loginService
        .sendLoginEmail(this.email)
        .pipe(showSpinner())
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.toastService.add({
              message: this.translateService.instant('LogIn.WeHaveSentAnEmailToast', { email: this.email }),
              type: 'success',
            });
          },
          error: (error) => {
            if (error.status === 429) {
              this.toastService.add({
                message: error.error.message ?? this.translateService.instant('Common.Errors.UnknownError'),
                type: 'error',
              });
            } else {
              this.toastService.add({
                message: this.translateService.instant('LogIn.CouldNotSendEmail'),
                type: 'error',
              });
            }
          },
        });
    }
  }

  cancel(): void {
    this.cancelEvent.emit();
  }
}
