<div class="card-responsive card-responsive-small">
  <div class="card-body">
    <div class="text-center">
      <div class="headline-x-small mb-6">
        @if (parkingSession) {
          <span [innerText]="'Onboarding.CurrentParkingSessions' | translate"></span>
        } @else {
          <span [innerText]="'Onboarding.NoCurrentParkingSession' | translate"></span>
        }
      </div>

      <arivo-license-plate
        [country]="licensePlate?.license_plate_country"
        [licensePlate]="licensePlate?.license_plate"
        [checkInvalid]="true"
      />
      <button class="button-primary-text mx-auto mt-2" (click)="previous()" [innerText]="'Common.Change' | translate"></button>

      <div class="mt-4 mb-8">
        @if (!parkingSession) {
          <div class="headline-x-small mb-4" [innerText]="'Onboarding.IsYourLicensePlateCorrect' | translate"></div>
          <p class="body-medium" [innerText]="'Onboarding.WeWereNotAbleToFindACurrentParkingSession' | translate"></p>
        } @else if (!parkingSession.is_cleared) {
          <p class="body-medium mb-4" [innerText]="'Onboarding.SignUpAndExitLocation' | translate"></p>
        } @else if (parkingSession.is_cleared) {
          <div class="headline-x-small mb-4" [innerText]="'Onboarding.YouCanExit' | translate"></div>
          <p class="body-medium mb-2" [innerText]="'Onboarding.YouAreAlreadyRegisteredWithThisLicensePlate' | translate"></p>
        }
      </div>

      @if (!parkingSession) {
        <arivo-step-footer [center]="true">
          <button class="button-primary" (click)="next()" [innerText]="'Onboarding.SignUpNow' | translate"></button>
          <button class="button-primary-outlined" [routerLink]="['/login']" [innerText]="'Common.Cancel' | translate"></button>
        </arivo-step-footer>
      } @else if (!parkingSession.is_cleared) {
        <arivo-step-footer [center]="true">
          <button class="button-primary" (click)="next()" [innerText]="'Onboarding.SignUpNowAndExit' | translate"></button>
          <button class="button-primary-outlined" [routerLink]="['/login']" [innerText]="'Common.Cancel' | translate"></button>
        </arivo-step-footer>
      } @else if (parkingSession.is_cleared) {
        <arivo-step-footer [center]="true">
          <button class="button-primary" (click)="next()" [innerText]="'Onboarding.SignUpNow' | translate"></button>
          <button class="button-primary-outlined" [routerLink]="['/login']" [innerText]="'Common.Cancel' | translate"></button>
        </arivo-step-footer>
      }
    </div>
  </div>
</div>
